<template>
  <section id="dashboard-prediccion">
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
 
    <b-row>
      <b-col sm="12">
        <ag-grid-table
          :configUrl="historicoPrediccionesConfig"
          :dataUrl="historicoPrediccionesData"
          :editUrl="historicoPrediccionesEdit"
          :actions="actions"
        >
        </ag-grid-table>
      </b-col>

      <prediccion-ver-detalles-modal
        ref="prediccion-modal"
      ></prediccion-ver-detalles-modal>
    </b-row>
  </section>
</template>

<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import useApiServices from '@/services/useApiServices.js';

import RepeatStatistics from "@/views/clients/camanchaca/components/RepeatStatistics";

import PrediccionVerDetallesModal from './PrediccionVerDetallesModal.vue';

import axios from "@axios";

import
{
  BRow,
  BCol,

} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    RepeatStatistics,
    PrediccionVerDetallesModal,


  },

  mounted()
  {

    axios.get(useApiServices.historicoPrediccionesStatistics)
      .then((response) =>
      {
        this.itemsData = response.data;
      })
      .catch((error) =>
      {

      });


  },

  methods: {

    verDetalles(params)
    {
      console.log(params)
      this.$refs['prediccion-modal'].show(params.data)
    },

  

  },

  data()
  {
    return {

      historicoPrediccionesConfig: useApiServices.historicoPrediccionesConfig,
      historicoPrediccionesData: useApiServices.historicoPrediccionesData,
      historicoPrediccionesEdit: useApiServices.historicoPrediccionesEdit,

      itemsData: [],



      actions: [
        { name: "Detalles", icon: "AlignJustifyIcon", action: this.verDetalles },
      ],


    };
  },
};
</script>
<style lang="scss">
span {
  font-size: 14px;
}
</style>